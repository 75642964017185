import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices

import appReducer from './slices/app';
import contentReducer from './slices/content';
import userReducer from './slices/user';
import patientReducer from './slices/patient';
import specialistReducer from './slices/specialist';
import adminReducer from './slices/admin';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  patient: patientReducer,
  specialist: specialistReducer,
  admin: adminReducer,
  content: contentReducer
});

export { rootPersistConfig, rootReducer };
