import { Typography } from '@mui/material';
import useLocales from '@/hooks/useLocales';
import NotificationTypesEnum from '@/enums/notification-types.enum';
import { PATH_DASHBOARD } from '@/routes/paths';

export function renderContent(notification, formattedNotification) {
  const { title: formattedTitle, description: formattedDescription } = formattedNotification;

  const title = (
    <Typography variant="subtitle2">
      {formattedTitle}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {formattedDescription}
      </Typography>
    </Typography>
  );

  return {
    avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
    title
  };

  /* if (notification.type._id === NotificationTypesEnum.PatientDocumentAdded.id) {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type._id === NotificationTypesEnum.PatientNoteAdded.id) {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title
  };*/
}

const getPatientFullName = (patient) => {
  return `${patient.firstName} ${patient.lastName}`;
};

const getNotificationFromFullName = (notificationFrom) => {
  return `${notificationFrom.firstName} ${notificationFrom.lastName}`;
};

export function formatContent(notification) {
  const { translate } = useLocales('notification');
  const { from: notificationFrom } = notification;
  const { meta: notificationMeta } = notification;

  let patient = {};
  let notificationContent = {
    title: '',
    description: ''
  };
  if (notification.type._id === NotificationTypesEnum.PatientDocumentAdded.id) {
    patient = notificationMeta.patientDocumentAdded.patient;
    const totalDocumentsAdded = notificationMeta.patientDocumentAdded.documentList;
    notificationContent = {
      title: translate('notificationList.typePatientDocumentAdded.title', { count: totalDocumentsAdded.length }),
      description: translate('notificationList.typePatientDocumentAdded.description', {
        count: totalDocumentsAdded.length,
        fromFullName: getNotificationFromFullName(notificationFrom),
        patientFullName: getPatientFullName(patient),
        nbDocuments: totalDocumentsAdded.length
      }),
      navigateLink: `${PATH_DASHBOARD.patient.profile}/${patient._id}?tab=documents`
    };
  }

  if (notification.type._id === NotificationTypesEnum.PatientRequestCreated.id) {
    patient = notificationMeta.patientRequestCreated.patient;
    notificationContent = {
      title: translate('notificationList.typePatientRequestCreated.title'),
      description: translate('notificationList.typePatientRequestCreated.description', {
        fromFullName: getNotificationFromFullName(notificationFrom),
        patientFullName: getPatientFullName(patient)
      }),
      navigateLink: `${PATH_DASHBOARD.patient.root}/${patient._id}/edit`
    };
  }
  return notificationContent;
}
