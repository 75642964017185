import { createSlice, createSelector, createDraftSafeSelector } from '@reduxjs/toolkit';
// utils
// configs
import { adminApi } from '@/_apis_/adminApi';
// ----------------------------------------------------------------------
const stateName = 'admin';

const initialState = {
  isLoading: false,
  error: false,
  adminList: [],
  adminCBList: [],
  adminRoleList: [],
  pages: {
    ['edit']: 'idle'
  }
};

const slice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ADMIN BY SLUG
    getAdminBySlugSuccess(state, action) {
      state.isLoading = false;
      state.adminProfile = action.payload;
    },

    // GET ADMIN LIST
    getAdminListSuccess(state, action) {
      state.isLoading = false;
      state.adminList = action.payload;
    },

    getAdminCBListSuccess(state, action) {
      state.isLoading = false;
      state.adminCBList = action.payload;
    },

    getAdminRoleListSuccess(state, action) {
      state.isLoading = false;
      state.adminRoleList = action.payload;
    },

    adminEditPageLoaded(state) {
      state.pages['edit'] = 'succeeded';
    },

    // RESEND INVITATION ADMIN
    resendInvitationAdminSuccess(state, action) {},
    resendInvitationAdminFail(state, action) {}
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getAdminListSuccess, resendInvitationAdminSuccess, resendInvitationAdminFail } = slice.actions;

// ----------------------------------------------------------------------

export function getAdminList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { userAdminList } = await adminApi.getAdminList();
      dispatch(slice.actions.getAdminListSuccess(userAdminList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdminBySlug(adminSlug) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { admin } = await adminApi.getAdminBySlug(adminSlug);
      dispatch(slice.actions.getAdminBySlugSuccess(admin));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdminCBList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { userAdminList } = await adminApi.getAdminCBList();
      dispatch(slice.actions.getAdminCBListSuccess(userAdminList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdminRoleList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { userAdminRoleList } = await adminApi.getAdminRoleList();
      dispatch(slice.actions.getAdminRoleListSuccess(userAdminRoleList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const getAdminEditPageData = (adminSlug) => async (dispatch) => {
  await Promise.all([dispatch(getAdminBySlug(adminSlug)), dispatch(getAdminRoleList())]);
  return dispatch(slice.actions.adminEditPageLoaded());
};

export function resendInvitationAdmin(adminId) {
  return async (dispatch) => {
    try {
      await adminApi.resendInvitationAdmin(adminId);
      return dispatch(slice.actions.resendInvitationAdminSuccess());
    } catch (error) {
      return dispatch(slice.actions.resendInvitationAdminFail(error));
    }
  };
}
// Selectors
const selectSelf = (state) => state;
const getAdminState = createDraftSafeSelector(selectSelf, (state) => state[stateName]);
export const getAdminEditPage = createDraftSafeSelector(getAdminState, (state) => state.pages['edit']);

export const getAdminEditPageIsLoadedSelector = (state) =>
  createSelector([getAdminEditPage], (editPage) => {
    if (editPage === 'succeeded') return true;
    return false;
  })(state);
