export default {
  PatientDocumentAdded: {
    id: '61f86075bc2de43f3ac3d292',
    name: 'PATIENT_DOCUMENT_ADDED'
  },
  PatientNoteAdded: {
    id: '61f8607a751e5db1701c19a2',
    name: 'PATIENT_NOTE_ADDED'
  },
  PatientRequestCreated: {
    id: '64f62e7c7b7169acd0a8b7e0',
    name: 'PATIENT_REQUEST_CREATED'
  }
};
