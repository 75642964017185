import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
import { useSelector } from '@/redux/store';

// ----------------------------------------------------------------------

export default function PatientCreationByMonthsStats() {
  const { newPatientPerMonthStatistics } = useSelector((state) => state.app);
  const [graphState, setGraphState] = useState({
    options: {
      tooltip: {
        marker: { show: false },
        y: {
          title: {
            formatter: () => ''
          }
        }
      },
      chart: {
        id: 'apexchart-example'
      },
      xaxis: {
        title: {
          text: 'Mois'
        },
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yaxis: {
        forceNiceScale: true,
        title: {
          text: 'Patient'
        }
      }
    },
    series: [
      {
        data: []
      }
    ]
  });

  useEffect(() => {
    setGraphState({
      options: {
        ...graphState.options
      },
      series: [
        {
          data: newPatientPerMonthStatistics.map((pStat) => pStat.total)
        }
      ]
    });
  }, [newPatientPerMonthStatistics]);

  return (
    <Card>
      <CardHeader title="Nouveaux patients par mois" subheader="Année 2024" />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart options={graphState.options} series={graphState.series} type="bar" height={350} />
      </Box>
    </Card>
  );
}
