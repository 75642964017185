import PropTypes from 'prop-types';
import { Container, Box, Typography } from '@mui/material';
import useAuth from '@/hooks/useAuth';
import { appConfig } from '@/config';
import { MotionContainer, varBounceIn } from '@/components/animate';
import { motion } from 'framer-motion';
import Page from '@/components/Page';
import useLocales from '@/hooks/useLocales';

// ----------------------------------------------------------------------

UserGuard.propTypes = {
  userTypes: PropTypes.array,
  userRoles: PropTypes.array,
  children: PropTypes.node
};

export default function UserGuard({ userTypes = [], userRoles = [], children }) {
  const { translate } = useLocales('errorPages');
  const { user } = useAuth();
  if (!userTypes.includes(user.userType)) {
    return (
      <Page
        title={`403 Unauthorized | ${appConfig.platformName}`}
        id="page"
        sx={{ display: 'flex', minHeight: '100%' }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center'
          }}
          id="container"
        >
          <MotionContainer initial="initial" open id="motion-container">
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <Typography
                sx={{
                  typography: 'h1',
                  overflow: 'hidden',
                  display: 'inline-flex',
                  color: 'primary.main'
                }}
              >
                403
              </Typography>
              <motion.div variants={varBounceIn}>
                <Typography variant="h3" paragraph>
                  {translate('errorPages.403.title')}
                </Typography>
              </motion.div>
            </Box>
          </MotionContainer>
        </Container>
      </Page>
    );
  }

  return <>{children}</>;
}
