// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginAdmin: path(ROOTS_AUTH, '/login/user-admin'),
  confirmation: path(ROOTS_AUTH, '/confirmation/:token'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  resetAdmin: path(ROOTS_AUTH, '/reset-password/user-admin'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    settings: path(ROOTS_DASHBOARD, '/settings')
  },
  content: {
    tutorials: path(ROOTS_DASHBOARD, '/content/tutorials'),
    tutorial: path(ROOTS_DASHBOARD, '/content/tutorial'),
    newTutorial: path(ROOTS_DASHBOARD, '/content/new-tutorial'),
    editById: path(ROOTS_DASHBOARD, `/content/tutorial/:id/edit`)
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    notifications: path(ROOTS_DASHBOARD, '/user/notifications'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    myAccount: path(ROOTS_DASHBOARD, '/user/my-account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  patient: {
    root: path(ROOTS_DASHBOARD, '/patient'),
    profile: path(ROOTS_DASHBOARD, '/patient/profile'),
    list: path(ROOTS_DASHBOARD, '/patient/list'),
    workflowSteps: path(ROOTS_DASHBOARD, '/patient/workflow/steps'),
    statistics: path(ROOTS_DASHBOARD, '/patient/statistics'),
    listMe: path(ROOTS_DASHBOARD, '/patient/list/me'),
    newPatient: path(ROOTS_DASHBOARD, '/patient/new'),
    editById: path(ROOTS_DASHBOARD, `/patient/:patientId/edit`)
  },
  // Section Spécialiste (Référent)
  specialist: {
    root: path(ROOTS_DASHBOARD, '/specialist'),
    profile: path(ROOTS_DASHBOARD, '/specialist/profile'),
    list: path(ROOTS_DASHBOARD, '/specialist/list'),
    newSpecialist: path(ROOTS_DASHBOARD, '/specialist/new'),
    editById: path(ROOTS_DASHBOARD, `/specialist/:specialistSlug/edit`)
  },
  // Section Admin (Référé)
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    profile: path(ROOTS_DASHBOARD, '/admin/profile'),
    list: path(ROOTS_DASHBOARD, '/admin/list'),
    newAdmin: path(ROOTS_DASHBOARD, '/admin/new'),
    editById: path(ROOTS_DASHBOARD, `/admin/:adminSlug/edit`)
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
