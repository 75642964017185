import { reject } from 'lodash';
import { createDraftSafeSelector, createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
// utils
// configs
import { patientApi } from '@/_apis_/patientApi';
// other redux
import { getSpecialistCBList } from '@/redux/slices/specialist';
import { getAdminCBList } from '@/redux/slices/admin';
import UserTypesEnum from '@/enums/user-types.enum';

// ----------------------------------------------------------------------

const stateName = 'patient';

const initialState = {
  isLoading: false,
  error: false,
  patientProfile: null,
  patientDocuments: [],
  patientGalleryPictures: [],
  patientDocumentsByWorkflow: [],
  patientDocumentsForWorkflow: [],
  patientDocumentsFeedFormat: [],
  patientWorkflowTemplateDocumentList: [],
  patientNotes: [],
  patientList: [],
  patientWorkflowStepCBList: [],
  status: '',
  followingPatient: false,
  pages: {
    ['form']: {
      loadingStatus: 'idle',
      loadingFailedReason: null
    },
    ['profile']: {
      loadingStatus: 'idle',
      loadingFailedReason: null
    }
  }
};

const slice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PATIENT PROFILE
    getPatientProfileSuccess(state, action) {
      state.isLoading = false;
      state.patientProfile = action.payload;
    },

    getPatientProfileFailed(state, action) {
      state.isLoading = false;
    },

    // GET PATIENT LIST
    getPatientListSuccess(state, action) {
      state.isLoading = false;
      state.patientList = action.payload;
    },

    // GET PATIENT DOCUMENTS
    getPatientDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.patientDocuments = action.payload;
    },

    // GET PATIENT DOCUMENTS
    getPatientDocumentsByWorkflowSuccess(state, action) {
      state.isLoading = false;
      state.patientDocumentsByWorkflow = action.payload;
    },

    // GET PATIENT DOCUMENTS
    getPatientDocumentsForWorkflowSuccess(state, action) {
      state.isLoading = false;
      state.patientDocumentsForWorkflow = action.payload;
    },

    // GET PATIENT DOCUMENTS
    getPatientDocumentsFeedFormatSuccess(state, action) {
      state.isLoading = false;
      state.patientDocumentsFeedFormat = action.payload;
    },

    // DELETE PATIENT
    deletePatientSuccess(state, action) {
      const { patientId } = action.payload;
      state.patientList = reject(state.patientList, { _id: patientId });
    },
    // DELETE PATIENT DOCUMENT

    // DELETE PATIENT NOTE SUCCESS
    deletePatientDocumentSuccess(state, action) {
      const { documentId, workflowId } = action.payload;
      const workflowIndex = state.patientDocumentsByWorkflow.findIndex((workflow) => workflow._id === workflowId);
      state.patientDocumentsByWorkflow[workflowIndex].patientDocumentList = reject(
        state.patientDocumentsByWorkflow[workflowIndex].patientDocumentList,
        {
          _id: documentId
        }
      );
    },

    // DELETE PATIENT NOTE FAIL
    deletePatientDocumentFail(state, action) {},

    // PUSH PATIENT NOTE
    pushPatientNote(state, action) {
      const patientNote = action.payload;
      state.isLoading = false;
      const transNote = {
        id: patientNote._id,
        content: patientNote.content,
        from: patientNote.createdByAdmin || patientNote.createdBySpecialist,
        createdAt: patientNote.createdAt
      };

      state.patientNotes.push(transNote);
    },

    // GET PATIENT NOTES
    getPatientNotesSuccess(state, action) {
      state.isLoading = false;
      state.patientNotes = action.payload;
    },

    getPatientFollowingStatusSuccess(state, action) {
      state.isLoading = false;
      state.followingPatient = action.payload;
    },

    // UPDATE PATIENT NOTE
    updatePatientNote(state, action) {
      const patientNote = action.payload;
      const updatedNoteIndex = state.patientNotes.findIndex((note) => note.id === patientNote._id);
      state.patientNotes[updatedNoteIndex] = {
        id: patientNote._id,
        content: patientNote.content,
        ...(!isEmpty(patientNote.createdByAdmin)
          ? { from: patientNote.createdByAdmin }
          : { from: patientNote.createdBySpecialist }),
        //from: patientNote.createdByAdmin || patientNote.createdBySpecialist,
        createdAt: patientNote.createdAt
      };
    },

    // UPDATE PATIENT WORKFLOW
    updatePatientWorkflowSuccess(/*state, action*/) {
      //const patientWorkflow = action.payload;
    },

    updatePatientWorkflowFail(state, action) {},

    // DELETE PATIENT WORKFLOW
    deletePatientWorkflowSuccess(/*state, action*/) {
      //const patientWorkflow = action.payload;
    },

    deletePatientWorkflowFail(state, action) {},

    // UPLOAD DOCUMENTS
    uploadDocumentsByPatientIdAndWflStepIdSuccess(state, action) {},

    uploadDocumentsByPatientIdAndWflStepIdFail(state, action) {},

    // DELETE PATIENT NOTE SUCCESS
    deletePatientNoteSuccess(state, action) {
      state.patientNotes = reject(state.patientNotes, { id: action.payload });
    },

    // DELETE PATIENT NOTE FAIL
    deletePatientNoteFail(state, action) {},

    // GET PATIENT WORKFLOW STEP LIST
    getPatientWorkflowStepListSuccess(state, action) {
      state.isLoading = false;
      state.patientWorkflowStepCBList = action.payload;
    },
    // GET PATIENT WORKFLOW TEMPLATE DOCUMENT LIST
    getPatientWorkflowTemplateDocumentListSuccess(state, action) {
      state.isLoading = false;
      state.patientWorkflowTemplateDocumentList = action.payload;
    },

    // GET PATIENT GALLERY PICTURES LIST
    getPatientGalleryPicturesSuccess(state, action) {
      state.isLoading = false;
      state.patientGalleryPictures = action.payload;
    },

    // GET PATIENT FORM DATA
    patientFormDataIsLoading(state) {
      state.pages['form'] = 'idle';
    },

    patientFormDataLoaded(state) {
      state.pages['form'] = {
        loadingStatus: 'succeeded',
        loadingFailedReason: null
      };
    },

    patientFormDataFailed(state, action) {
      state.pages['form'] = {
        loadingStatus: 'failed',
        loadingFailedReason: action.payload
      };
    },

    // GET PATIENT PROFILE
    patientProfileDataIsLoading(state) {
      state.pages['profile'] = 'idle';
    },

    patientProfileDataLoaded(state) {
      state.pages['profile'] = {
        loadingStatus: 'succeeded',
        loadingFailedReason: null
      };
    },

    patientProfileDataFailed(state, action) {
      state.pages['profile'] = {
        loadingStatus: 'failed',
        loadingFailedReason: action.payload
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getPatientProfileSuccess,
  getPatientProfileFailed,
  deletePatientDocumentSuccess,
  deletePatientDocumentFail,
  getPatientListSuccess,
  pushPatientNote,
  updatePatientNote,
  updatePatientWorkflowSuccess,
  updatePatientWorkflowFail,
  deletePatientNoteSuccess,
  deletePatientNoteFail,
  deletePatientWorkflowSuccess,
  deletePatientWorkflowFail,
  uploadDocumentsByPatientIdAndWflStepIdSuccess,
  uploadDocumentsByPatientIdAndWflStepIdFail
} = slice.actions;

// ----------------------------------------------------------------------

// #region FETCH Actions
export function getPatientList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { patientList } = await patientApi.getPatientList();
      dispatch(slice.actions.getPatientListSuccess(patientList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMyPatientList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { patientList } = await patientApi.getMyPatientList();
      dispatch(slice.actions.getPatientListSuccess(patientList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientProfileByPatientId(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { patient } = await patientApi.getPatientProfileById(patientId);
      return dispatch(slice.actions.getPatientProfileSuccess(patient));
    } catch (error) {
      /*console.log('getPatientProfileByPatientId: error', error);
      console.log('error.response:', error.response);*/
      dispatch(slice.actions.hasError(error));
      return dispatch(
        slice.actions.getPatientProfileFailed({
          status: error.response.status,
          statusText: error.response.statusText
        })
      );
    }
  };
}

export function getPatientDocumentsByPatientId(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { patientDocuments } = await patientApi.getPatientDocumentsByPatientId(patientId);
      dispatch(slice.actions.getPatientDocumentsSuccess(patientDocuments));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientDocumentsByPatientIdAndWorkflowStepId(patientId, workflowStepId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { patientWorkflowStepDocuments } = await patientApi.getPatientDocumentsByPatientIdAndWorkflowStepId(
        patientId,
        workflowStepId
      );
      dispatch(slice.actions.getPatientDocumentsForWorkflowSuccess(patientWorkflowStepDocuments));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientDocumentsByPatientIdGrpdByWfl(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { patientWorkflows } = await patientApi.getPatientDocumentsByPatientId(patientId, true);
      dispatch(slice.actions.getPatientDocumentsByWorkflowSuccess(patientWorkflows));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientDocumentsByPatientIdFeedFormat(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { patientDocuments } = await patientApi.getPatientDocumentsByPatientId(patientId, false, true);
      dispatch(slice.actions.getPatientDocumentsFeedFormatSuccess(patientDocuments));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientFollowingStatusByPatientId(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const patientFollowingStatus = await patientApi.getPatientFollowingStatusByPatientId(patientId);
      dispatch(slice.actions.getPatientFollowingStatusSuccess(patientFollowingStatus));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientNotesByPatientId(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const patientNoteList = await patientApi.getPatientNotesByPatientId(patientId).then(({ patientNotes }) => {
        return patientNotes.map((patientNote) => {
          return {
            id: patientNote._id,
            content: patientNote.content,
            ...(!isEmpty(patientNote.createdByAdmin)
              ? { from: patientNote.createdByAdmin }
              : { from: patientNote.createdBySpecialist }),
            //...(!isEmpty(patientNote.createdBySpecialist) && { from: patientNote.createdBySpecialist }),
            //from: patientNote.createdByAdmin || patientNote.createdBySpecialist,
            createdAt: patientNote.createdAt
          };
        });
      });
      dispatch(slice.actions.getPatientNotesSuccess(patientNoteList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientWorkflowStepList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { workflowStepList } = await patientApi.getPatientWorkflowStepList();
      dispatch(slice.actions.getPatientWorkflowStepListSuccess(workflowStepList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientWorkflowTemplateDocumentList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { workflowTemplateDocList } = await patientApi.getPatientWorkflowTemplateDocumentList();
      dispatch(slice.actions.getPatientWorkflowTemplateDocumentListSuccess(workflowTemplateDocList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientGalleryPicturesByPatientId(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const patientGalleryPictureList = await patientApi
        .getPatientGalleryPicturesByPatientId(patientId)
        .then(({ patientGalleryPictures }) => {
          return patientGalleryPictures.map((patientGalleryPicture) => {
            return {
              id: patientGalleryPicture._id,
              title: patientGalleryPicture.title,
              from: patientGalleryPicture.createdByAdmin || patientGalleryPicture.createdBySpecialist,
              postAt: patientGalleryPicture.createdAt,
              imageUrl: patientGalleryPicture.signedUrl,
              slug: patientGalleryPicture.slug
            };
          });
        });
      dispatch(slice.actions.getPatientGalleryPicturesSuccess(patientGalleryPictureList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/*export const getPatientFormData = (isEdit, patientId, patientWorkflowStepId) => async (dispatch) => {
  await Promise.all([
    dispatch(getSpecialistCBList()),
    dispatch(getAdminCBList()),
    dispatch(getPatientProfileByPatientId(patientId)),
    dispatch(getPatientDocumentsByPatientIdAndWorkflowStepId(patientId, patientWorkflowStepId))
  ]);
  return dispatch(slice.actions.patientFormDataLoaded());
};*/

export const getPatientFormData = (isEdit, patientId, patientWorkflowStepId, userType) => async (dispatch) => {
  dispatch(slice.actions.patientFormDataIsLoading());
  const utilityPromises = [dispatch(getPatientWorkflowTemplateDocumentList())];
  if (userType === UserTypesEnum.Admin) {
    utilityPromises.push(dispatch(getSpecialistCBList()), dispatch(getAdminCBList()));
  }

  if (isEdit) {
    const patientProfileLoadingResult = await dispatch(getPatientProfileByPatientId(patientId));
    if (patientProfileLoadingResult.type === getPatientProfileFailed.type) {
      return dispatch(slice.actions.patientFormDataFailed(patientProfileLoadingResult.payload));
    } else if (patientProfileLoadingResult.type === getPatientProfileSuccess.type) {
      await Promise.all([
        ...utilityPromises,
        dispatch(getPatientDocumentsByPatientIdAndWorkflowStepId(patientId, patientWorkflowStepId))
      ]);
      return dispatch(slice.actions.patientFormDataLoaded());
    }
  } else {
    await Promise.all([...utilityPromises]);
    return dispatch(slice.actions.patientFormDataLoaded());
  }
};

export const getPatientProfileData = (patientId) => async (dispatch) => {
  dispatch(slice.actions.patientProfileDataIsLoading());
  const patientProfileLoadingResult = await dispatch(getPatientProfileByPatientId(patientId));
  if (patientProfileLoadingResult.type === getPatientProfileFailed.type) {
    return dispatch(slice.actions.patientProfileDataFailed(patientProfileLoadingResult.payload));
  } else if (patientProfileLoadingResult.type === getPatientProfileSuccess.type) {
    await Promise.all([
      //dispatch(getPatientProfileByPatientId(patientId)),
      dispatch(getPatientNotesByPatientId(patientId)),
      dispatch(getPatientDocumentsByPatientIdFeedFormat(patientId)),
      dispatch(getPatientDocumentsByPatientIdGrpdByWfl(patientId)),
      dispatch(getPatientDocumentsByPatientId(patientId)),
      dispatch(getPatientFollowingStatusByPatientId(patientId)),
      dispatch(getPatientWorkflowStepList())
    ]);
    return dispatch(slice.actions.patientProfileDataLoaded());
  }
};

// #endregion

export function updatePatientWorkflow(workflowId, patientId, workflowStepId, workflowCompletedOn) {
  return async (dispatch) => {
    try {
      const updatedPatientWorkflow = await patientApi.updatePatientWorkflow(
        workflowId,
        patientId,
        workflowStepId,
        workflowCompletedOn
      );
      return dispatch(slice.actions.updatePatientWorkflowSuccess(updatedPatientWorkflow));
    } catch (error) {
      console.log('error: ', error);
      return dispatch(slice.actions.updatePatientWorkflowFail(error));
    }
  };
}

export function uploadDocumentsByPatientIdAndWflStepId(
  patientId,
  workflowStepId,
  workflowStepDate,
  workflowStepDocuments
) {
  return async (dispatch) => {
    try {
      await patientApi.uploadDocumentsByPatientIdAndWflStepId(
        patientId,
        workflowStepId,
        workflowStepDate,
        workflowStepDocuments
      );
      return dispatch(slice.actions.uploadDocumentsByPatientIdAndWflStepIdSuccess());
    } catch (error) {
      console.log('error: ', error);
      return dispatch(slice.actions.uploadDocumentsByPatientIdAndWflStepIdFail(error));
    }
  };
}

// #region DELETE Actions

export function deletePatient(patientId) {
  return async (dispatch) => {
    try {
      await patientApi.deletePatient(patientId);
      return dispatch(
        slice.actions.deletePatientSuccess({
          patientId
        })
      );
    } catch (error) {
      return dispatch(slice.actions.deletePatientDocumentFail(error));
    }
  };
}

export function deletePatientDocument(patientId, documentId, workflowId) {
  return async (dispatch) => {
    try {
      await patientApi.deletePatientDocument(patientId, documentId);
      return dispatch(
        slice.actions.deletePatientDocumentSuccess({
          documentId,
          workflowId
        })
      );
    } catch (error) {
      return dispatch(slice.actions.deletePatientDocumentFail(error));
    }
  };
}

export function deletePatientWorkflow(patientId, workflowId) {
  return async (dispatch) => {
    try {
      await patientApi.deletePatientWorkflow(patientId, workflowId);
      return dispatch(slice.actions.deletePatientWorkflowSuccess());
    } catch (error) {
      return dispatch(slice.actions.deletePatientWorkflowFail(error));
    }
  };
}

export function deletePatientNote(patientId, noteId) {
  return async (dispatch) => {
    try {
      await patientApi.deletePatientNote(patientId, noteId);
      return dispatch(slice.actions.deletePatientNoteSuccess(noteId));
    } catch (error) {
      return dispatch(slice.actions.deletePatientNoteFail(error));
    }
  };
}

// #endregion

// #region Selectors
const selectSelf = (state) => state;
const getPatientState = createDraftSafeSelector(selectSelf, (state) => state[stateName]);
export const getPatientFormPage = createDraftSafeSelector(getPatientState, (state) => state.pages['form']);
export const getPatientProfilePage = createDraftSafeSelector(getPatientState, (state) => state.pages['profile']);
export const getPatientNotes = createDraftSafeSelector(getPatientState, (state) => state.patientNotes);

export const getPatientFormDataIsLoadedSelector = (state) =>
  createSelector([getPatientFormPage], (formPage) => {
    return formPage === 'succeeded';
  })(state);

export const getPatientProfileDataIsLoadedSelector = (state) =>
  createSelector([getPatientProfilePage], (profilePage) => {
    //console.log('profilePage: ', profilePage);
    return profilePage.loadingStatus === 'succeeded';
  })(state);

export const getPatientNoteByIdSelector = (state, noteId) =>
  createSelector([getPatientNotes], (notes) => {
    return notes.find((note) => note.id === noteId);
  })(state);
// #endregion
