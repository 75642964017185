import { apiConfig } from '@/config';
import axiosWithAuth from '@/utils/axiosAuthenticated';

class SpecialistApi {
  // #region GET Methods
  async getSpecialistList(searchTerms) {
    const params = {
      ...(searchTerms && { search_terms: searchTerms })
    };
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user-specialist/all`, { params }).then(({ data: { data } }) => {
      return data;
    });
  }

  async getSpecialistBySlug(specialistSlug) {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user-specialist/${specialistSlug}`).then(({ data: { data } }) => {
      return data;
    });
  }

  async getSpecialistCBList() {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user-specialist/all?simplified=true`).then(({ data: { data } }) => {
      return data;
    });
  }
  async getSpecialistRoleList() {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user-specialist/role/all`).then(({ data: { data } }) => {
      return data;
    });
  }
  // #endregion
  // #region POST Methods
  async createSpecialist(specialistValues) {
    return axiosWithAuth.post(`${apiConfig.apiUrl}/user-specialist`, specialistValues).then(({ data: { data } }) => {
      return data;
    });
  }

  async resendInvitationSpecialist(specialistId) {
    return axiosWithAuth
      .post(`${apiConfig.apiUrl}/user-specialist/${specialistId}/resend/confirm`)
      .then(({ data: { data } }) => {
        return data;
      });
  }
  // #endregion
  // #region PUT Methods
  async updateSpecialist(specialistValues) {
    return axiosWithAuth.put(`${apiConfig.apiUrl}/user-specialist`, specialistValues).then(({ data: { data } }) => {
      return data;
    });
  }
  // #endregion
  // #region DELETE Methods
  // #endregion
}

export const specialistApi = new SpecialistApi();
