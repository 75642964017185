import { apiConfig } from '@/config';
import axiosWithAuth from '@/utils/axiosAuthenticated';

class PatientApi {
  // #region GET Methods
  async getPatientList(searchTerms) {
    const params = {
      ...(searchTerms && { search_terms: searchTerms })
    };
    return axiosWithAuth.get(`${apiConfig.apiUrl}/patient/all`, { params }).then(({ data: { data } }) => {
      return data;
    });
  }

  async getMyPatientList(searchTerms) {
    const params = {
      ...(searchTerms && { search_terms: searchTerms })
    };
    return axiosWithAuth.get(`${apiConfig.apiUrl}/patient/all/me`, { params }).then(({ data: { data } }) => {
      return data;
    });
  }

  async getPatientProfileById(patientId) {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/patient/${patientId}/profile`).then(({ data: { data } }) => {
      return data;
    });
  }

  async getPatientFollowingStatusByPatientId(patientId) {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/patient/${patientId}/follow`).then(
      ({
        data: {
          data: { following }
        }
      }) => {
        return following;
      }
    );
  }

  async getPatientDocumentsByPatientId(patientId, groupByWorkflow = false, feedFormat = false) {
    const params = {
      ...(groupByWorkflow && { group_workflow: 'true' }),
      ...(feedFormat && { feed_format: 'true' })
    };
    return axiosWithAuth
      .get(`${apiConfig.apiUrl}/patient/${patientId}/profile/documents`, { params })
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async getPatientDocumentsByPatientIdAndWorkflowStepId(patientId, workflowStepId) {
    return axiosWithAuth
      .get(`${apiConfig.apiUrl}/patient/${patientId}/profile/documents/${workflowStepId}`)
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async getPatientNotesByPatientId(patientId) {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/patient/${patientId}/notes`).then(({ data: { data } }) => {
      return data;
    });
  }

  async getPatientGalleryPicturesByPatientId(patientId) {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/patient/${patientId}/gallery/pictures`).then(({ data: { data } }) => {
      return data;
    });
  }

  async getPatientWorkflowStepList() {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/patient/workflow/steps`).then(({ data: { data } }) => {
      return data;
    });
  }

  async getPatientWorkflowTemplateDocumentList() {
    return axiosWithAuth
      .get(`${apiConfig.apiUrl}/patient/workflow/template/documents/all`)
      .then(({ data: { data } }) => {
        return data;
      });
  }

  // #endregion
  // #region POST Methods
  async createPatient(patientValues) {
    return axiosWithAuth.post(`${apiConfig.apiUrl}/patient`, patientValues).then(({ data: { data } }) => {
      return data;
    });
  }

  async createPatientRequest(patientValues) {
    return axiosWithAuth
      .post(`${apiConfig.apiUrl}/patient/request`, patientValues, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async createPatientNote(patientId, noteValues) {
    return axiosWithAuth.post(`${apiConfig.apiUrl}/patient/${patientId}/notes`, noteValues).then(
      ({
        data: {
          data: { note }
        }
      }) => {
        return note;
      }
    );
  }

  async createPatientWorkflowStep(workflowStepValues) {
    return axiosWithAuth.post(`${apiConfig.apiUrl}/patient/workflow/steps`, workflowStepValues).then(
      ({
        data: {
          data: { workflowStep }
        }
      }) => {
        return workflowStep;
      }
    );
  }

  async addPatientWorkflowStep(patientId, workflowStep) {
    return axiosWithAuth.post(`${apiConfig.apiUrl}/patient/${patientId}/workflow/step`, workflowStep).then(
      ({
        data: {
          data: { workflowStep }
        }
      }) => {
        return workflowStep;
      }
    );
  }

  async uploadDocumentsByPatientIdAndWflStepId(patientId, workflowStepId, workflowStepDate, workflowStepDocuments) {
    const filesData = {};
    workflowStepDocuments.map((file, idx) => {
      Object.assign(filesData, {
        [`file-${idx}`]: {
          title: file.title
        }
      });
    });

    let formData = new FormData();
    formData.append(`workflowStepId`, workflowStepId);
    formData.append(`workflowStepDate`, workflowStepDate);

    formData.append(`filesData`, JSON.stringify(filesData));
    workflowStepDocuments.map((file, idx) => {
      formData.append(`file-${idx}`, file);
    });

    return axiosWithAuth.post(`${apiConfig.apiUrl}/patient/${patientId}/upload/files`, formData).then((result) => {
      return result;
    });
  }

  // #endregion
  // #region PUT Methods
  async updatePatient(patientValues) {
    return axiosWithAuth.put(`${apiConfig.apiUrl}/patient`, patientValues).then(({ data: { data } }) => {
      return data;
    });
  }

  async updatePatientNote(patientId, noteId, noteValues) {
    return axiosWithAuth.put(`${apiConfig.apiUrl}/patient/${patientId}/notes/${noteId}`, noteValues).then(
      ({
        data: {
          data: { note }
        }
      }) => {
        return note;
      }
    );
  }

  async updatePatientWorkflowStep(workflowStepId, workflowStepValues) {
    return axiosWithAuth.put(`${apiConfig.apiUrl}/patient/workflow/steps/${workflowStepId}`, workflowStepValues).then(
      ({
        data: {
          data: { workflowStep }
        }
      }) => {
        return workflowStep;
      }
    );
  }

  async updatePatientWorkflow(workflowId, patientId, workflowStepId, workflowCompletedOn) {
    return axiosWithAuth
      .put(`${apiConfig.apiUrl}/patient/workflow/${workflowId}`, {
        patientId,
        workflowStepId,
        workflowCompletedOn
      })
      .then(
        ({
          data: {
            data: { workflow }
          }
        }) => {
          return workflow;
        }
      );
  }

  async updatePatientFollowingStatus(patientId, doFollow) {
    return axiosWithAuth.put(`${apiConfig.apiUrl}/patient/${patientId}/follow`, { follow: doFollow }).then(
      ({
        data: {
          data: { follow }
        }
      }) => {
        return follow;
      }
    );
  }

  // #endregion
  // #region DELETE Methods

  async deletePatient(patientId) {
    return axiosWithAuth.delete(`${apiConfig.apiUrl}/patient/${patientId}`).then(({ data: { data } }) => {
      return data;
    });
  }

  async deletePatientDocument(patientId, documentId) {
    return axiosWithAuth
      .delete(`${apiConfig.apiUrl}/patient/${patientId}/documents/${documentId}`)
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async deletePatientWorkflow(patientId, workflowId) {
    return axiosWithAuth
      .delete(`${apiConfig.apiUrl}/patient/${patientId}/workflow/${workflowId}`)
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async deletePatientNote(patientId, noteId) {
    return axiosWithAuth
      .delete(`${apiConfig.apiUrl}/patient/${patientId}/notes/${noteId}`)
      .then(({ data: { data } }) => {
        return data;
      });
  }

  // #endregion
}

export const patientApi = new PatientApi();
