import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { useDispatch } from '@/redux/store';
import { getUserNotificationList, getUserNotificationListSuccess } from '@/redux/slices/user';
// import { useDispatch } from '@/redux/store';
// import { pushUserNotification } from '@/redux/slices/user';
// ----------------------------------------------------------------------
// hooks
// import useSocket from '@/hooks/useSocket';
import { NotificationSound } from '@/assets';
import useSound from 'use-sound';
import { mergeMap, timer } from 'rxjs';
import { userApi } from '@/_apis_/userApi';

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const dispatch = useDispatch();
  const fetchNotificationsSubscription$ = useRef(null);

  useEffect(() => {
    dispatch(getUserNotificationList());
    fetchNotificationsSubscription$.current = timer(10000, 10000)
      .pipe(mergeMap(() => userApi.myNotification()))
      .subscribe((userNotificationList) => {
        dispatch(getUserNotificationListSuccess(userNotificationList));
      });
    return () => {
      if (fetchNotificationsSubscription$.current) fetchNotificationsSubscription$.current.unsubscribe();
    };
  }, []);
  const [play] = useSound(NotificationSound, { volume: 1, soundEnabled: true });
  // const dispatch = useDispatch();
  // const { socket } = useSocket();

  const playBellRing = () => {
    play();
  };

  /*const onNotification = useCallback(
    (newNotification) => {
      console.log('onNotification: ', newNotification);
      dispatch(pushUserNotification(newNotification));
      playBellRing();
    },
    [play]
  );*/

  /*  useEffect(() => {
    if (socket) socket.on('notification', onNotification);

    return () => {
      if (socket) socket.off('notification', onNotification);
    };
  }, [socket, onNotification]);*/

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

export default DashboardNavbar;
//export default memo(DashboardNavbar);
