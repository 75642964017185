import { apiConfig } from '@/config';
import axiosWithAuth from '@/utils/axiosAuthenticated';

class AdminApi {
  // #region GET Methods
  async getAdminList(searchTerms) {
    const params = {
      ...(searchTerms && { search_terms: searchTerms })
    };
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user-admin/all`, { params }).then(({ data: { data } }) => {
      return data;
    });
  }

  async getAdminCBList() {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user-admin/all?simplified=true`).then(({ data: { data } }) => {
      return data;
    });
  }

  async getAdminBySlug(adminSlug) {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user-admin/${adminSlug}`).then(({ data: { data } }) => {
      return data;
    });
  }

  async getAdminRoleList() {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user-admin/role/all`).then(({ data: { data } }) => {
      return data;
    });
  }
  // #endregion
  // #region POST Methods
  async createAdmin(adminValues) {
    return axiosWithAuth.post(`${apiConfig.apiUrl}/user-admin`, adminValues).then(({ data: { data } }) => {
      return data;
    });
  }
  async resendInvitationAdmin(adminId) {
    return axiosWithAuth.post(`${apiConfig.apiUrl}/user-admin/${adminId}/resend/confirm`).then(({ data: { data } }) => {
      return data;
    });
  }
  // #endregion
  // #region PUT Methods
  async updateAdmin(adminValues) {
    return axiosWithAuth.put(`${apiConfig.apiUrl}/user-admin`, adminValues).then(({ data: { data } }) => {
      return data;
    });
  }
  // #endregion
  // #region DELETE Methods
  // #endregion
}

export const adminApi = new AdminApi();
