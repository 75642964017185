import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import LanguageDetector from 'i18next-browser-languagedetector';
//
import enLocales from './en.json';
import frLocales from './fr.json';

// ----------------------------------------------------------------------
// Authentication Module
import enAuthenticationLocales from '@/modules/authentication/_i18n/en.json';
import frAuthenticationLocales from '@/modules/authentication/_i18n/fr.json';
// Patient Module
import enPatientLocales from '@/modules/patient/_i18n/en.json';
import frPatientLocales from '@/modules/patient/_i18n/fr.json';
// Specialist Module
import enUserSpecialistLocales from '@/modules/user-specialist/_i18n/en.json';
import frUserSpecialistLocales from '@/modules/user-specialist/_i18n/fr.json';
// Admin Module
import enUserAdminLocales from '@/modules/user-admin/_i18n/en.json';
import frUserAdminLocales from '@/modules/user-admin/_i18n/fr.json';
// Content Module
import enContentLocales from '@/modules/content/_i18n/en.json';
import frContentLocales from '@/modules/content/_i18n/fr.json';
// Notification Module
import enNotificationLocales from '@/modules/user/_i18n/notifications/en.json';
import frNotificationLocales from '@/modules/user/_i18n/notifications/fr.json';
// -----------------------------------------------------------------------
// Errors Pages
import enErrorPagesLocales from '@/modules/error/_i18n/en.json';
import frErrorPagesLocales from '@/modules/error/_i18n/fr.json';

// Layout
import enLayoutLocales from '@/layouts/_i18n/en.json';
import frLayoutLocales from '@/layouts/_i18n/fr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources: {
      en: {
        // Namespaces
        common: enLocales,
        authentication: enAuthenticationLocales,
        patient: enPatientLocales,
        userSpecialist: enUserSpecialistLocales,
        userAdmin: enUserAdminLocales,
        content: enContentLocales,
        notification: enNotificationLocales,
        layout: enLayoutLocales,
        errorPages: enErrorPagesLocales
      },
      fr: {
        // Namespaces
        common: frLocales,
        authentication: frAuthenticationLocales,
        patient: frPatientLocales,
        userSpecialist: frUserSpecialistLocales,
        userAdmin: frUserAdminLocales,
        content: frContentLocales,
        notification: frNotificationLocales,
        layout: frLayoutLocales,
        errorPages: frErrorPagesLocales
      }
    },
    lng: localStorage.getItem('i18nextLng') || 'fr',
    fallbackLng: 'fr',
    debug: false,
    /* ns: ['common'],
    defaultNS: 'common', */
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
