import { useCallback, useState } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useField } from 'formik';

AutoFillAwareTextField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any
};

export default function AutoFillAwareTextField({ name, onChange, inputProps, InputLabelProps, ...rest }) {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const [fieldHasValue, setFieldHasValue] = useState(false);
  const makeAnimationStartHandler = (stateSetter) => (e) => {
    const autofilled = !!e.target?.matches('*:-webkit-autofill');
    if (e.animationName === 'mui-auto-fill') {
      stateSetter(autofilled);
    }

    if (e.animationName === 'mui-auto-fill-cancel') {
      stateSetter(autofilled);
    }
  };

  const _onChange = useCallback(
    (e) => {
      setValue(e.target.value);
      setFieldHasValue(e.target.value !== '');
    },
    [onChange]
  );

  return (
    <TextField
      {...rest}
      inputProps={{
        onAnimationStart: makeAnimationStartHandler(setFieldHasValue),
        ...inputProps
      }}
      InputLabelProps={{
        shrink: fieldHasValue,
        ...InputLabelProps
      }}
      onChange={_onChange}
      onBlur={(e) => {
        // Set touched state manually
        helpers.setTouched(true);
        field.onBlur(e);
      }}
    />
  );
}
