import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '@/routes/paths';
// hooks
import useAuth from '@/hooks/useAuth';
import useIsMountedRef from '@/hooks/useIsMountedRef';
//
import { MIconButton } from '@/components/@material-extend';
import useLocales from '@/hooks/useLocales';
import PropTypes from 'prop-types';
import AutoFillAwareTextField from '@/components/AutoFillAwareTextField';

// ----------------------------------------------------------------------

LoginForm.propTypes = {
  isAdmin: PropTypes.bool
};

export default function LoginForm({ isAdmin }) {
  const { loginAdmin, loginSpecialist } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { translate } = useLocales();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate('common:form.fields.email.validationValid'))
      .required(translate('common:form.fields.email.validationRequired')),
    password: Yup.string().required(translate('common:form.fields.password.validationRequired'))
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        if (isAdmin) {
          await loginAdmin(values.email, values.password);
        } else {
          await loginSpecialist(values.email, values.password);
        }

        enqueueSnackbar('Login success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);

        if (isMountedRef.current) {
          setSubmitting(false);
          if (error.response.status === 401) {
            setErrors({ afterSubmit: translate('authentication:login.form401ErrorMessage') });
          } else {
            setErrors({ afterSubmit: error.message });
          }
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <AutoFillAwareTextField
            fullWidth
            autoComplete="username"
            type="email"
            label={translate('common:form.fields.email.placeholder')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <AutoFillAwareTextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={translate('common:form.fields.password.placeholder')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {isAdmin ? (
            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.login}>
              {translate('authentication:login.formLoginAsSpecialistLink')}
            </Link>
          ) : (
            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.loginAdmin}>
              {translate('authentication:login.formLoginAsAdminLink')}
            </Link>
          )}

          <Link
            component={RouterLink}
            variant="subtitle2"
            to={isAdmin ? PATH_AUTH.resetAdmin : PATH_AUTH.resetPassword}
          >
            {translate('authentication:login.forgotPassword')}
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {translate('authentication:login.formLoginButton')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
