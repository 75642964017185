import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '@/components/animate';
import Page from '@/components/Page';
// hooks
import useLocales from '@/hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const ButtonStyle = styled(Button)((/* { theme } */) => ({
  textTransform: 'none'
}));

// ----------------------------------------------------------------------

const PageLinkExpired = ({ type = 'default', link = '/auth/login' }) => {
  const pageTypeTranslateKey = `linkExpired.${type}`;
  const { translate } = useLocales('errorPages');

  return (
    <RootStyle title={translate(`${pageTypeTranslateKey}.pageTitle`)}>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                {translate(`${pageTypeTranslateKey}.title`)}
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary', margin: '16px 0px' }}>
              {translate(`${pageTypeTranslateKey}.description`)}
            </Typography>

            <ButtonStyle to={link} size="large" variant="contained" component={RouterLink}>
              {translate(`${pageTypeTranslateKey}.btnText`)}
            </ButtonStyle>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

PageLinkExpired.propTypes = {
  type: PropTypes.oneOf(['default', 'confirmation', 'reset-password']),
  link: PropTypes.string
};

export default PageLinkExpired;
