import { useEffect } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
// hooks
import useLocales from '@/hooks/useLocales';
// layouts
// components
import Page from '@/components/Page';
import { LoginForm } from '@/modules/authentication/components/login';
import { ImplantologieSIDIllustration } from '@/assets';
import PropTypes from 'prop-types';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

Login.propTypes = {
  isAdmin: PropTypes.bool
};

export default function Login({ isAdmin }) {
  const { translate } = useLocales('authentication');

  return (
    <RootStyle title={translate('confirmation.pageTitle')}>
      <Container maxWidth="xl">
        <ContentStyle>
          <ImplantologieSIDIllustration sx={{ mb: 10, height: 240 }} />
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              {isAdmin ? (
                <Typography sx={{ color: 'text.secondary' }} variant="h3" gutterBottom>
                  {translate('login.userAdmin')}
                </Typography>
              ) : (
                <Typography sx={{ color: 'text.secondary' }} variant="h3" gutterBottom>
                  {translate('login.userSpecialist')}
                </Typography>
              )}

              <Typography variant="h4" gutterBottom>
                {translate('login.title')}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{translate('login.subTitle')}</Typography>
            </Box>
          </Stack>
          <LoginForm isAdmin={isAdmin} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
