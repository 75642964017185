// routes
import { PATH_DASHBOARD } from '@/routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  settings: getIcon('ic_settings'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  help: getIcon('ic_help')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    titleI18n: 'sideBar.generalSection.title',
    items: [
      {
        title: 'app',
        titleI18n: 'sideBar.generalSection.appSection.title',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Patient',
        titleI18n: 'sideBar.managementSection.patientSection.title',
        icon: ICONS.user,
        children: [
          {
            title: 'list',
            titleI18n: 'sideBar.managementSection.patientSection.listSubItemTitle',
            path: PATH_DASHBOARD.patient.list
          },
          {
            title: 'workflow',
            titleI18n: 'sideBar.managementSection.patientSection.listSubItemTitle2',
            path: PATH_DASHBOARD.patient.workflowSteps,
            icon: ICONS.settings
          },
          {
            title: 'statistics',
            titleI18n: 'sideBar.managementSection.patientSection.listSubItemTitle3',
            path: PATH_DASHBOARD.patient.statistics,
            icon: ICONS.settings
          }
        ]
      },
      {
        title: 'Specialist',
        titleI18n: 'sideBar.managementSection.specialistSection.title',
        icon: ICONS.user,
        children: [
          {
            title: 'list',
            titleI18n: 'sideBar.managementSection.specialistSection.listSubItemTitle',
            path: PATH_DASHBOARD.specialist.list
          }
        ]
      },
      {
        title: 'Adminstrator',
        titleI18n: 'sideBar.managementSection.adminSection.title',
        icon: ICONS.user,
        children: [
          {
            title: 'list',
            titleI18n: 'sideBar.managementSection.adminSection.listSubItemTitle',
            path: PATH_DASHBOARD.admin.list
          }
        ]
      }
    ]
  },
  // CONTENT
  // ----------------------------------------------------------------------
  {
    subheader: 'content',
    titleI18n: 'sideBar.contentSection.title',
    items: [
      {
        title: 'tutorials',
        titleI18n: 'sideBar.contentSection.tutorialSection.title',
        path: PATH_DASHBOARD.content.tutorials,
        icon: ICONS.help
      }
    ]
  }
];

export default sidebarConfig;
