import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import TokenExpiredGuard from '../guards/TokenExpiredGuard';

// import UserGuard from '../guards/UserGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import TokenExpiredResetPasswordGuard from '@/guards/TokenExpiredResetPasswordGuard';
import ResetPasswordChange from '@/modules/authentication/pages/ResetPasswordChange';
import UserGuard from '@/guards/UserGuard';
import UserTypesEnum from '@/enums/user-types.enum';
import PatientStatistics from '@/modules/patient/pages/PatientStatistics';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'login/user-admin',
          element: (
            <GuestGuard>
              <Login isAdmin={true} />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'reset-password/change/:token',
          element: (
            <TokenExpiredResetPasswordGuard>
              <ResetPasswordChange />
            </TokenExpiredResetPasswordGuard>
          )
        },
        { path: 'reset-password/user-admin', element: <ResetPassword isAdmin={true} /> },
        { path: 'verify', element: <VerifyCode /> },
        {
          path: 'confirmation/:token',
          element: (
            <TokenExpiredGuard>
              <Confirmation />
            </TokenExpiredGuard>
          )
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        //{ path: 'settings', element: <GeneralSettings /> },
        {
          path: 'patient',
          children: [
            {
              path: 'list',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <PatientList />
                </UserGuard>
              )
            },
            { path: 'list/me', element: <PatientList me /> },
            {
              path: 'workflow/steps',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <PatientWorkflowSteps />
                </UserGuard>
              )
            },
            { path: 'new', element: <PatientCreate /> },
            { path: ':patientId/edit', element: <PatientCreate /> },
            { path: 'profile/:patientId', element: <PatientProfile /> },
            {
              path: 'statistics',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <PatientStatistics />
                </UserGuard>
              )
            }
          ]
        },
        {
          path: 'specialist',
          children: [
            {
              path: 'list',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <SpecialistList />
                </UserGuard>
              )
            },
            {
              path: 'new',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <SpecialistCreate />
                </UserGuard>
              )
            },
            {
              path: ':specialistSlug/edit',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <SpecialistCreate />
                </UserGuard>
              )
            }
          ]
        },
        {
          path: 'admin',
          children: [
            {
              path: 'list',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <AdminList />
                </UserGuard>
              )
            },
            {
              path: 'new',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <AdminCreate />
                </UserGuard>
              )
            },
            {
              path: ':adminSlug/edit',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <AdminCreate />
                </UserGuard>
              )
            }
          ]
        },
        {
          path: 'user',
          children: [
            { path: 'notifications', element: <MyNotification /> }
            /* {
              path: 'my-account',
              element: <MyAccount />
            }*/
          ]
        },
        {
          path: 'content',
          children: [
            { path: 'tutorials', element: <TutorialList /> },
            { path: 'tutorial/:title', element: <Tutorial /> },
            {
              path: 'tutorial/:id/edit',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <TutorialCreate isEdit />
                </UserGuard>
              )
            },
            {
              path: 'new-tutorial',
              element: (
                <UserGuard userTypes={[UserTypesEnum.Admin]}>
                  <TutorialCreate />
                </UserGuard>
              )
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to="/dashboard" />
    }
  ]);
}

// IMPORT COMPONENTS

// App Module
const GeneralApp = Loadable(lazy(() => import('../modules/app/pages/GeneralApp')));

// Settings Module
// const GeneralSettings = Loadable(lazy(() => import('../modules/settings/pages/GeneralSettings')));

// Authentication Module
const Login = Loadable(lazy(() => import('../modules/authentication/pages/Login')));
const Confirmation = Loadable(lazy(() => import('../modules/authentication/pages/Confirmation')));
const ResetPassword = Loadable(lazy(() => import('../modules/authentication/pages/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../modules/authentication/pages/VerifyCode')));

// Patient Module
const PatientList = Loadable(lazy(() => import('../modules/patient/pages/PatientList')));
const PatientCreate = Loadable(lazy(() => import('../modules/patient/pages/PatientCreate')));
const PatientProfile = Loadable(lazy(() => import('../modules/patient/pages/PatientProfile')));
const PatientWorkflowSteps = Loadable(lazy(() => import('../modules/patient/pages/PatientWorkflowSteps')));

// User Specialist Module
const SpecialistList = Loadable(lazy(() => import('../modules/user-specialist/pages/SpecialistList')));
const SpecialistCreate = Loadable(lazy(() => import('../modules/user-specialist/pages/SpecialistCreate')));

// User Admin Module
const AdminList = Loadable(lazy(() => import('../modules/user-admin/pages/AdminList')));
const AdminCreate = Loadable(lazy(() => import('../modules/user-admin/pages/AdminCreate')));

// User Shared
const MyNotification = Loadable(lazy(() => import('../modules/user/pages/MyNotification')));
//const MyAccount = Loadable(lazy(() => import('../modules/user/pages/MyAccount')));

// Tutorial
const Tutorial = Loadable(lazy(() => import('../modules/content/pages/Tutorial')));
const TutorialList = Loadable(lazy(() => import('../modules/content/pages/TutorialList')));
const TutorialCreate = Loadable(lazy(() => import('../modules/content/pages/TutorialCreate')));

// Error Module
/* const Page500 = Loadable(lazy(() => import('../modules/error/pages/Page500')));
const NotFound = Loadable(lazy(() => import('../modules/error/pages/Page404')));
const LinkExpired = Loadable(lazy(() => import('../modules/error/pages/PageLinkExpired')));
 */
// Layouts
/* const LogoOnlyLayout = Loadable(lazy(() => import('../layouts/LogoOnlyLayout')));
 */
