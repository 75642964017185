import PropTypes from 'prop-types';
// material
import { List, ListSubheader } from '@mui/material';
// components
import Scrollbar from '@/components/Scrollbar';
import useLocales from '@/hooks/useLocales';
import { NotificationItem } from '@/modules/user/components/NotificationItem';
// ----------------------------------------------------------------------

NotificationList.propTypes = {
  userNotifications: PropTypes.array.isRequired
};

export function NotificationList({ userNotifications }) {
  const { translate } = useLocales('notification');

  return (
    <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
      <List
        disablePadding
        subheader={
          <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            {translate('notificationPopover.allNotificationHeader')}
          </ListSubheader>
        }
      >
        {userNotifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </List>

      {/*<List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {userNotifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>*/}
    </Scrollbar>
  );
}
