import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Stack, TextField, Link, Container, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useLocales from '@/hooks/useLocales';
// routes
import { PATH_AUTH } from '@/routes/paths';
// layouts
import AuthLayout from '@/layouts/AuthLayout';
// components
import Page from '@/components/Page';
import { MHidden } from '@/components/@material-extend';

import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// Apis
import { authApi } from '@/_apis_/authApi';
import { ImplantologieSIDIllustration } from '@/assets';
import { regexEmail } from '@/utils/regExUtils';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ResetPasswordChange() {
  const navigate = useNavigate();
  const { translate } = useLocales('authentication');
  //const [tokenIsValid, setTokenIsValid] = useState(true);
  const tokenIsValid = true;
  const { token } = useParams();

  const ChangePassWordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(regexEmail, translate('confirmation.form.password.validationMatch'))
      .required(translate('confirmation.form.password.validationRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], translate('confirmation.form.validationMustMatch'))
      .required(translate('confirmation.form.confirmPassword.validationRequired'))
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: ChangePassWordSchema,
    onSubmit: async (values, { /* setSubmitting, */ resetForm }) => {
      await authApi.resetPassword(token, values);
      resetForm();
      navigate(PATH_AUTH.login);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <RootStyle title={translate('resetPasswordChange.pageTitle')}>
      <AuthLayout>
        {translate('resetPasswordChange.formAlreadyHaveAnAccount')} &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.login}>
          {translate('confirmation.login')}
        </Link>
      </AuthLayout>

      <Container>
        <ContentStyle>
          <ImplantologieSIDIllustration sx={{ mb: 10, height: 240 }} />
          <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {translate('resetPasswordChange.title')}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{translate('resetPasswordChange.subTitle')}</Typography>
            </Box>
          </Box>
          {!tokenIsValid && (
            <Box sx={{ mb: 3 }}>
              <Alert variant="outlined" severity="error" onClose={() => {}}>
                {translate('resetPasswordChange.formRequestError')}
              </Alert>
            </Box>
          )}

          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3} alignItems="flex-end">
                <TextField
                  {...getFieldProps('password')}
                  fullWidth
                  autoComplete="on"
                  type="password"
                  label={translate('resetPasswordChange.form.password.placeholder')}
                  error={Boolean(touched.password && errors.password)}
                  helperText={
                    (touched.password && errors.password) || translate('resetPasswordChange.form.passwordLengthLabel')
                  }
                />

                <TextField
                  {...getFieldProps('confirmPassword')}
                  fullWidth
                  autoComplete="on"
                  type="password"
                  label={translate('resetPasswordChange.form.confirmPassword.placeholder')}
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {translate('resetPasswordChange.formButtonText')}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              {translate('resetPasswordChange.formAlreadyHaveAnAccount')} &nbsp;
              <Link to={PATH_AUTH.login} component={RouterLink}>
                {translate('resetPasswordChange.login')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
