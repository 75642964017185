// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function ImplantologieSIDIllustration({ ...other }) {
  return (
    <Box {...other}>
      <img src="/static/auth/implantologie_sid_logo.jpg" alt="implantologie-sid-logo" />
    </Box>
  );
}
