// ----------------------------------------------------------------------

export function getFullNameToInitials(firstName, lastName) {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
}

export function getFullName(firstName, lastName) {
  return `${firstName} ${lastName}`;
}

export function getFullNameInitials(fullName) {
  const nameArr = fullName.split('');
  let initials = nameArr.filter(function (char) {
    return /[A-Z]/.test(char);
  });
  return initials.slice(0, 2).join('');
}
