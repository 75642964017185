import axios from 'axios';
import { apiConfig } from '@/config';
import axiosWithAuth from '@/utils/axiosAuthenticated';

class AuthApi {
  // #region GET Methods
  // #endregion
  // #region POST Methods
  async loginAdmin({ email, password }) {
    return axios
      .post(`${apiConfig.apiUrl}/user-admin/login`, {
        email,
        password
      })
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async loginSpecialist({ email, password }) {
    return axios
      .post(`${apiConfig.apiUrl}/user-specialist/login`, {
        email,
        password
      })
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async validateUserConfirmToken(confirmToken) {
    return axios.get(`${apiConfig.apiUrl}/user/confirm/${confirmToken}`).then(({ data: { data } }) => {
      return data;
    });
  }

  async validateUserResetPasswordToken(confirmToken) {
    return axios.get(`${apiConfig.apiUrl}/user/reset-password/${confirmToken}`).then(({ data: { data } }) => {
      return data;
    });
  }

  async resetPasswordRequest(data) {
    return axios.post(`${apiConfig.apiUrl}/user/request/reset-password`, data).then(({ data: { data } }) => {
      return data;
    });
  }

  async resetPassword(resetPasswordToken, data) {
    return axios
      .post(`${apiConfig.apiUrl}/user/reset-password/${resetPasswordToken}`, data)
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async activateAccount(confirmToken, data) {
    return axios.post(`${apiConfig.apiUrl}/user/confirm/${confirmToken}`, data).then(({ data: { data } }) => {
      return data;
    });
  }

  async verifyAuthentication() {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user/verify-auth`).then(({ data }) => data);
  }

  // #endregion
  // #region PUT Methods
  // #endregion
  // #region DELETE Methods
  // #endregion
}

export const authApi = new AuthApi();
