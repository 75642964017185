import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField } from '@mui/material';
import { useSelector } from '@/redux/store';

// ----------------------------------------------------------------------

export default function SpecialistReferringPerYearStats() {
  const { specialistReferringPerYearStatistics } = useSelector((state) => state.app);
  const [seriesData, setSeriesData] = useState([10]);
  const [serieSelected, setSerieSelected] = useState(10);
  const [graphHeight, setGraphHeight] = useState('auto');

  const [graphState, setGraphState] = useState({
    options: {
      tooltip: {
        marker: { show: false },
        y: {
          title: {
            formatter: () => ''
          }
        }
      },
      plotOptions: {
        bar: { horizontal: true, barHeight: '60%', columnWidth: '100%', borderRadius: 2 }
      },
      xaxis: {
        title: {
          text: 'Patient'
        },
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return val;
          }
        },
        categories: []
      },
      yaxis: {
        forceNiceScale: true
      }
    },
    series: [
      {
        data: []
      }
    ]
  });

  useEffect(() => {
    const step = 10;
    const slicedStats = specialistReferringPerYearStatistics.slice(0, serieSelected);
    const nbSeries = Math.ceil(specialistReferringPerYearStatistics.length / step);

    setSeriesData(
      Array(nbSeries)
        .fill(0)
        .map((_, idx) => (idx + 1) * step)
    );

    setGraphHeight(slicedStats.length ? `${32 * slicedStats.length}px` : '100%');
    const listOfCategories = slicedStats.map((sStat) => `${sStat.firstName} ${sStat.lastName}`);
    setGraphState({
      options: {
        ...graphState.options,
        xaxis: {
          ...graphState.options.xaxis,
          categories: listOfCategories
        }
      },
      series: [
        {
          data: slicedStats.map((sStat) => sStat.total)
        }
      ]
    });
  }, [specialistReferringPerYearStatistics, serieSelected]);

  const handleChangeSeriesData = (event) => {
    setSerieSelected(event.target.value);
  };

  return (
    <Card>
      <CardHeader
        title="Nombre de patients référés par spécialiste"
        subheader="Année 2024"
        action={
          <TextField
            select
            fullWidth
            value={serieSelected}
            SelectProps={{ native: true }}
            onChange={handleChangeSeriesData}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
              '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
              '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
            }}
          >
            {seriesData.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        }
      />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart
          options={graphState.options}
          series={graphState.series}
          type="bar"
          height={graphHeight}
          /*height={
            specialistReferringPerYearStatistics.length
              ? `${32 * specialistReferringPerYearStatistics.length}px`
              : '100%'
          }*/
        />
      </Box>
    </Card>
  );
}
