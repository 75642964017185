// hooks
import useAuth from '@/hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import { getFullName } from '@/utils/userUtils';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const {
    user: { firstName, lastName }
  } = useAuth();
  const userFullName = getFullName(firstName, lastName);
  return (
    <MAvatar alt={userFullName} color={createAvatar(userFullName).color} {...other}>
      {createAvatar(userFullName).name}
    </MAvatar>
  );
}
