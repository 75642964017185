import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const isLoggedIn = accessToken;

  if (isLoggedIn) {
    request.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  return request;
});

export default axiosInstance;
