import PropTypes from 'prop-types';
import { useDispatch } from '@/redux/store';
import { updateAllUserNotification } from '@/redux/slices/user';
import { Box, Tooltip, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { MIconButton } from '@/components/@material-extend';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import useLocales from '@/hooks/useLocales';

NotificationListHeader.propTypes = {
  totalUnRead: PropTypes.number.isRequired
};

export function NotificationListHeader({ totalUnRead }) {
  const dispatch = useDispatch();
  const { translate } = useLocales('notification');
  const handleMarkAllAsRead = () => {
    console.log('handleMarkAllAsRead');
    dispatch(
      updateAllUserNotification({
        isRead: true
      })
    );
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1">Notifications</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {translate('notificationPopover.totalUnreadMessages', {
            totalUnRead: totalUnRead
          })}
        </Typography>
      </Box>

      {totalUnRead > 0 && (
        <Tooltip title={translate('notificationPopover.markAllAsReadBtn')}>
          <MIconButton color="primary" onClick={handleMarkAllAsRead}>
            <Icon icon={doneAllFill} width={20} height={20} />
          </MIconButton>
        </Tooltip>
      )}
    </Box>
  );
}
