// material
import { styled } from '@mui/material/styles';
import { Box, LinearProgress } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
  return (
    <>
      <RootStyle {...other}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      </RootStyle>
    </>
  );
}
