import { apiConfig } from '@/config';
import axiosWithAuth from '@/utils/axiosAuthenticated';

class UserApi {
  // #region GET Methods
  async myProfile() {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user/my-profile`).then(({ data: { data } }) => {
      return data;
    });
  }

  async myNotification() {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/user/my-notification`).then(({ data: { data } }) => {
      const { userNotifications: userNotifs } = data;
      return userNotifs.map((userNotif) => {
        return {
          id: userNotif._id,
          isRead: userNotif.isRead,
          type: userNotif.type,
          meta: userNotif.meta,
          from: userNotif.fromUserAdmin || userNotif.fromUserSpecialist,
          createdAt: userNotif.createdAt
        };
      });
      //return data;
    });
  }

  // #endregion
  // #region POST Methods
  // #endregion
  // #region PUT Methods
  async updateNotificationByNotificationId(notificationId, notificationValue) {
    return axiosWithAuth
      .put(`${apiConfig.apiUrl}/user/my-notification/${notificationId}`, { isRead: notificationValue.isRead })
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async updateAllNotification(notificationValue) {
    return axiosWithAuth
      .put(`${apiConfig.apiUrl}/user/my-notification`, { isRead: notificationValue.isRead })
      .then(({ data: { data } }) => {
        return data;
      });
  }

  // #endregion
  // #region DELETE Methods
  // #endregion
}

export const userApi = new UserApi();
