import { createSlice } from '@reduxjs/toolkit';
// utils
// configs
import { userApi } from '@/_apis_/userApi';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  notificationList: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USER NOTIFICATION LIST
    getUserNotificationListSuccess(state, action) {
      state.isLoading = false;
      state.notificationList = action.payload;
    },

    // PUSH USER NOTIFICATION
    pushUserNotification(state, action) {
      const userNotif = action.payload;
      const transNotif = {
        id: userNotif._id,
        isRead: userNotif.isRead,
        type: userNotif.type,
        meta: userNotif.meta,
        from: userNotif.fromUserAdmin || userNotif.fromUserSpecialist,
        createdAt: userNotif.createdAt
      };
      state.isLoading = false;
      state.notificationList.push(transNotif);
    },

    setNotificationIsRead(state, action) {
      const notificationId = action.payload;
      const updatedNotificationIndex = state.notificationList.findIndex((notif) => notif.id === notificationId);
      if (updatedNotificationIndex !== -1) {
        const foundNotification = state.notificationList[updatedNotificationIndex];
        state.notificationList[updatedNotificationIndex] = {
          ...foundNotification,
          isRead: true
        };
      }
    },

    setAllNotificationIsRead(state, action) {
      state.notificationList = state.notificationList.map((notification) => {
        return { ...notification, isRead: true };
      });
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { pushUserNotification, setNotificationIsRead, getUserNotificationListSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getUserNotificationList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const userNotificationList = await userApi.myNotification();
      dispatch(slice.actions.getUserNotificationListSuccess(userNotificationList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserNotificationByNotificationId(notificationId, notificationValue) {
  return async (dispatch) => {
    try {
      await userApi.updateNotificationByNotificationId(notificationId, notificationValue);
      return dispatch(slice.actions.setNotificationIsRead(notificationId));
    } catch (error) {
      console.log('Error updating notification: ', error);
    }
  };
}

export function updateAllUserNotification(notificationValue) {
  return async (dispatch) => {
    try {
      await userApi.updateAllNotification(notificationValue);
      return dispatch(slice.actions.setAllNotificationIsRead(notificationValue));
    } catch (error) {
      console.log('Error updating notification: ', error);
    }
  };
}
