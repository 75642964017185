import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// pages
import PageLinkExpired from '../modules/error/pages/PageLinkExpired';
// Apis
import { authApi } from '@/_apis_/authApi';
// ----------------------------------------------------------------------

TokenExpiredGuard.propTypes = {
  children: PropTypes.node
};

export default function TokenExpiredGuard({ children }) {
  const { token } = useParams();

  const [tokenExpiredGuardState, setTokenExpiredGuardState] = useState({
    tokenIsValid: false,
    tokenIsValided: false
  });

  useEffect(() => {
    authApi
      .validateUserConfirmToken(token)
      .then(() => {
        setTokenExpiredGuardState({
          tokenIsValid: true,
          tokenIsValided: true
        });
      })
      .catch(() => {
        setTokenExpiredGuardState({
          tokenIsValid: false,
          tokenIsValided: true
        });
      });
  }, [token]);

  if (tokenExpiredGuardState.tokenIsValided && !tokenExpiredGuardState.tokenIsValid) {
    return <PageLinkExpired type="confirmation" />;
  }

  if (tokenExpiredGuardState.tokenIsValided && tokenExpiredGuardState.tokenIsValid) {
    return <>{children}</>;
  }

  return null;
}
