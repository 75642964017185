import { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '@/hooks/useAuth';

// pages
import Login from '../modules/authentication/pages/Login';
import { authApi } from '@/_apis_/authApi';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, logout } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuthentication = async () => {
      authApi.verifyAuthentication().catch(({ response: { data } }) => {
        if ((data && data.message === 'expired') || data.message === 'invalid') {
          logout();
        }
      });
    };

    if (isAuthenticated) verifyAuthentication();
  }, [navigate]);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
