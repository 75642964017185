import { createSlice } from '@reduxjs/toolkit';
// utils
import { contentApi } from '@/_apis_/contentApi';
import { reject } from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  tutorials: [],
  tutorial: null,
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TUTORIALS
    getTutorialsSuccess(state, action) {
      state.isLoading = false;
      state.tutorials = action.payload;
    },

    // GET POST INFINITE
    getTutorialsInitial(state, action) {
      state.isLoading = false;
      state.tutorials = action.payload;
    },

    getMoreTutorials(state) {
      state.index = state.index + state.step;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getTutorialSuccess(state, action) {
      state.isLoading = false;
      state.tutorial = action.payload;
    },

    // GET RECENT POST
    getRecentTutorialsSuccess(state, action) {
      state.isLoading = false;
      state.recentTutorials = action.payload;
    },

    // DELETE CONTENT TUTORIAL SUCCESS
    deleteTutorialSuccess(state, action) {
      state.tutorials = reject(state.tutorials, { _id: action.payload });
    },

    // DELETE PATIENT NOTE FAIL
    deleteTutorialFail(state, action) {}
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteTutorialSuccess, deleteTutorialFail } = slice.actions;

// ----------------------------------------------------------------------

export function getAllTutorials() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { contentTutorialList } = await contentApi.getContentTutorialList();
      dispatch(slice.actions.getTutorialsSuccess(contentTutorialList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTutorial(tutorialId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { contentTutorial } = await contentApi.getContentTutorial(tutorialId);
      dispatch(slice.actions.getTutorialSuccess(contentTutorial));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteTutorial(contentTutorialId) {
  return async (dispatch) => {
    try {
      await contentApi.deleteContentTutorial(contentTutorialId);
      return dispatch(slice.actions.deleteTutorialSuccess(contentTutorialId));
    } catch (error) {
      return dispatch(slice.actions.deleteTutorialFail(error));
    }
  };
}
