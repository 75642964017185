import { createSlice } from '@reduxjs/toolkit';
import { appApi } from '@/_apis_/appApi';
import { getPatientWorkflowStepList, getPatientWorkflowTemplateDocumentList } from '@/redux/slices/patient';
// utils
// configs
// ----------------------------------------------------------------------

const stateName = 'app';

const initialState = {
  isLoading: false,
  error: false,
  dataLoaded: false,
  patientWorkflowStatistics: [],
  newPatientPerMonthStatistics: [],
  specialistReferringPerYearStatistics: [],
  pages: {
    ['statistics']: {
      loadingStatus: 'idle',
      loadingFailedReason: null
    }
  }
};

const slice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DASHBOARD SETTINGS
    getPatientWorkflowStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.patientWorkflowStatistics = action.payload;
    },

    getPatientsPerMonthStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.newPatientPerMonthStatistics = action.payload;
    },

    getSpecialistReferringPerYearStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.specialistReferringPerYearStatistics = action.payload;
    },

    appPageDataLoaded(state) {
      state.dataLoaded = true;
    },

    // GET PATIENT STATISTICS
    patientStatisticsPageIsLoading(state) {
      state.pages['statistics'] = 'idle';
    },

    patientStatisticsPageLoaded(state) {
      state.pages['statistics'] = {
        loadingStatus: 'succeeded',
        loadingFailedReason: null
      };
    },

    patientStatisticsPageFailed(state, action) {
      state.pages['statistics'] = {
        loadingStatus: 'failed',
        loadingFailedReason: action.payload
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getPatientsStatistics = (year) => async (dispatch) => {
  dispatch(slice.actions.patientStatisticsPageIsLoading());
  await Promise.all([
    dispatch(getPatientsPerMonthStatistics(year)),
    dispatch(getSpecialistReferringPerYearStatistics(year))
  ]);
  return dispatch(slice.actions.patientStatisticsPageLoaded());
};

export function getPatientsPerMonthStatistics(year) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { newPatientsPerMonth } = await appApi.getPatientsPerMonthStatistics(year);
      const newPatientsPerMonthMap = Array.from({ length: 12 }, (_, i) => i + 1).map((monthNumber) => {
        const monthData = newPatientsPerMonth.find((stat) => stat.month === monthNumber);
        return monthData != null ? monthData : { total: 0, month: monthNumber, year: year };
      });
      dispatch(slice.actions.getPatientsPerMonthStatisticsSuccess(newPatientsPerMonthMap));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSpecialistReferringPerYearStatistics(year) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { specialistReferringPatientPerYear } = await appApi.getSpecialistReferringPerYearStatistics(year);
      dispatch(slice.actions.getSpecialistReferringPerYearStatisticsSuccess(specialistReferringPatientPerYear));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientWorkflowStatistics() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { patientWorkflowStepsStatistics } = await appApi.getPatientWorkflowStatistics();
      dispatch(slice.actions.getPatientWorkflowStatisticsSuccess(patientWorkflowStepsStatistics));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const getAppData = () => async (dispatch) => {
  await Promise.all([
    dispatch(getPatientWorkflowStatistics()),
    dispatch(getPatientWorkflowStepList()),
    dispatch(getPatientWorkflowTemplateDocumentList())
  ]);
  return dispatch(slice.actions.appPageDataLoaded());
};
