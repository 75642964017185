import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '@/redux/store';
import { formatContent, renderContent } from '@/utils/notificationsUtils';
import { updateUserNotificationByNotificationId } from '@/redux/slices/user';
import { Avatar, Box, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import clockFill from '@iconify/icons-eva/clock-fill';
import { fToNow } from '@/utils/formatTime';

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

export function NotificationItem({ notification }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formattedNotification = formatContent(notification);
  const { avatar, title } = renderContent(notification, formattedNotification);

  const navigateEditPatient = () => {
    if (!notification.isRead) {
      dispatch(
        updateUserNotificationByNotificationId(notification.id, {
          ...notification,
          isRead: true
        })
      );
    }
    navigate(formattedNotification.navigateLink);
  };

  return (
    <ListItemButton
      onClick={() => navigateEditPatient()}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.isRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
