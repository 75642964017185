import { createSlice, createSelector, createDraftSafeSelector } from '@reduxjs/toolkit';
// utils
// configs
import { specialistApi } from '@/_apis_/specialistApi';
// ----------------------------------------------------------------------

const stateName = 'specialist';

const initialState = {
  isLoading: false,
  error: false,
  specialistProfile: {},
  specialistList: [],
  specialistCBList: [],
  pages: {
    ['edit']: 'idle'
  }
};

const slice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SPECIALIST BY SLUG
    getSpecialistBySlugSuccess(state, action) {
      state.isLoading = false;
      state.specialistProfile = action.payload;
    },

    // GET SPECIALIST LIST
    getSpecialistListSuccess(state, action) {
      state.isLoading = false;
      state.specialistList = action.payload;
    },

    getSpecialistCBListSuccess(state, action) {
      state.isLoading = false;
      state.specialistCBList = action.payload;
    },

    // GET SPECIALIST ROLE LIST
    getSpecialistRoleListSuccess(state, action) {
      state.isLoading = false;
      state.specialistRoleList = action.payload;
    },

    specialistEditPageLoaded(state) {
      state.pages['edit'] = 'succeeded';
    },

    // RESEND INVITATION SPECIALIST
    resendInvitationSpecialistSuccess(state, action) {},
    resendInvitationSpecialistFail(state, action) {}
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getSpecialistCBListSuccess,
  getSpecialistListSuccess,
  resendInvitationSpecialistSuccess,
  resendInvitationSpecialistFail
} = slice.actions;

// ----------------------------------------------------------------------

export function getSpecialistList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { userSpecialistList } = await specialistApi.getSpecialistList();
      dispatch(slice.actions.getSpecialistListSuccess(userSpecialistList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSpecialistBySlug(specialistSlug) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { specialist } = await specialistApi.getSpecialistBySlug(specialistSlug);
      dispatch(slice.actions.getSpecialistBySlugSuccess(specialist));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSpecialistCBList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { userSpecialistList } = await specialistApi.getSpecialistCBList();
      dispatch(slice.actions.getSpecialistCBListSuccess(userSpecialistList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSpecialistRoleList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { userSpecialistRoleList } = await specialistApi.getSpecialistRoleList();
      dispatch(slice.actions.getSpecialistRoleListSuccess(userSpecialistRoleList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resendInvitationSpecialist(specialistId) {
  return async (dispatch) => {
    try {
      await specialistApi.resendInvitationSpecialist(specialistId);
      return dispatch(slice.actions.resendInvitationSpecialistSuccess());
    } catch (error) {
      return dispatch(slice.actions.resendInvitationSpecialistFail(error));
    }
  };
}

export const getSpecialistEditPageData = (specialistSlug) => async (dispatch) => {
  await Promise.all([dispatch(getSpecialistBySlug(specialistSlug))]);
  return dispatch(slice.actions.specialistEditPageLoaded());
};

// Selectors
const selectSelf = (state) => state;
const getSpecialistState = createDraftSafeSelector(selectSelf, (state) => state[stateName]);
export const getSpecialistEditPage = createDraftSafeSelector(getSpecialistState, (state) => state.pages['edit']);

export const getSpecialistEditPageIsLoadedSelector = (state) =>
  createSelector([getSpecialistEditPage], (editPage) => {
    if (editPage === 'succeeded') return true;
    return false;
  })(state);
