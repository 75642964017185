import { apiConfig } from '@/config';
import axiosWithAuth from '@/utils/axiosAuthenticated';

class ContentApi {
  // #region GET Methods
  async getContentTutorialList(searchTerms) {
    const params = {
      ...(searchTerms && { search_terms: searchTerms })
    };
    return axiosWithAuth.get(`${apiConfig.apiUrl}/content/tutorial/all`, { params }).then(({ data: { data } }) => {
      return data;
    });
  }

  async getContentTutorial(tutorialId) {
    return axiosWithAuth.get(`${apiConfig.apiUrl}/content/tutorial/${tutorialId}`).then(({ data: { data } }) => {
      return data;
    });
  }

  async createContentTutorial(contentTutorialValues) {
    return axiosWithAuth
      .post(`${apiConfig.apiUrl}/content/tutorial`, contentTutorialValues)
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async updateContentTutorial(contentTutorialId, contentTutorialValues) {
    return axiosWithAuth
      .put(`${apiConfig.apiUrl}/content/tutorial/${contentTutorialId}`, contentTutorialValues)
      .then(({ data: { data } }) => {
        return data;
      });
  }

  async deleteContentTutorial(contentTutorialId) {
    return axiosWithAuth
      .delete(`${apiConfig.apiUrl}/content/tutorial/${contentTutorialId}`)
      .then(({ data: { data } }) => {
        return data;
      });
  }
}

export const contentApi = new ContentApi();
