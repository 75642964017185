import { useRef, useState } from 'react';
import { useSelector } from '@/redux/store';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
// material
import { alpha } from '@mui/material/styles';
import { Box, Badge, Button, Divider } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import { PATH_DASHBOARD } from '@/routes/paths';
import useLocales from '@/hooks/useLocales';
import { NotificationListHeader } from '@/modules/user/components/NotificationListHeader';
import { NotificationList } from '@/modules/user/components/NotificationList';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { translate } = useLocales('notification');
  const { notificationList: userNotifications } = useSelector((state) => state.user);
  const totalUnRead = userNotifications.filter((item) => !item.isRead).length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <NotificationListHeader totalUnRead={totalUnRead} />

        <Divider />
        <NotificationList userNotifications={userNotifications.slice(0, 5)} />
        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to={PATH_DASHBOARD.user.notifications}>
            {translate('notificationPopover.viewAllBtn')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
